export enum Resource {
  USERS = "users",
  PROPERTIES = "properties",
  ACTIVITIES = "activities",
  LODGING_TYPES = "lodging-types",
  AMENITIES = "amenities",
  REGIONS = "regions",
  PARTNERS = "partners",
  LOCAL_RECOMMENDATION_TYPES = "local-recommendation-types",
  LOCAL_RECOMMENDATIONS = "local-recommendations",
  HIGHLIGHTED_EXPERIENCES = "highlighted-experiences",
  GROUP_EVENTS = "group-events",
  AMBASSADORS = "ambassadors",
  ARTISTS = "artists",
  ARTISTS_ARTS = "artist-arts",
  ABOUT_US_QUOTES = "about-us-page-quotes",
  CONTACT_US_FAQS = "contact-us-page-faqs",
  CONTACT_US_PURPOSES = "contact-us-page-purposes",
  CAREERS_JOBS = "careers-page-jobs",
  DEVELOP_WITH_US_DEV_STORIES = "develop-with-us-page-dev-stories",
  DEVELOP_WITH_US_PARTNER_STORIES = "develop-with-us-page-partner-stories",
  GIVING_BACK_SECTIONS = "giving-back-page-sections",
  GROUP_EVENTS_PAGE_SECTIONS = "group-event-page-sections",
  PARTNERSHIP_PAGE_SECTIONS = "partnership-page-sections",
  PARTNERSHIP_PAGE_BRANDS = "partnership-page-brands",
  JOURNAL_TAGS = "journal-tags",
  JOURNAL_ARTICLES = "journal-articles",
  PROMOTIONS = "promotions",
  NAVIGATION_GROUPS_CARDS = "navigation-groups-cards",
  HOME_ACTIVITIES = "home-page-activities",
  HOME_EXPERIENCES = "home-page-experiences",
  HOME_PROPERTIES = "home-page-properties",
  GIVING_BACK = "giving-back-pages",
  HOME_PAGES = "home-pages",
  GROUPS_PAGES = "group-event-pages",
  ABOUT_US_PAGES = "about-us-pages",
  CAREERS_PAGES = "careers-pages",
  CONTACT_US_PAGES = "contact-us-pages",
  DEVELOP_WITH_US_PAGES = "develop-with-us-pages",
  OUR_FAMILY = "our-family-pages",
  PROMOTIONS_PAGE = "promotions-pages",
  PARTNER_PAGE = "partnership-pages",
  ACCESSIBILITY = "accessibility-pages",
  DESTINATIONS_TERMS = "destination-terms-pages",
  PRIVACY_POLICY = "privacy-policy-pages",
  TERMS_AND_CONDS = "terms-and-conditions-pages",
  ANNOUNCEMENTS_PAGE = "announcements-banners",
  MOBILE_APP = "app",
  REVIEWS = "property-reviews",
  DATA_EVENT_PAGES = "data-event-pages",
  EVENTS = "events",
  USER_MANAGEMENT = "users",
}
