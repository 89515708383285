import Cookies from "js-cookie";

export enum ROLES {
  SUPER_ADMIN = "super_admin",
  // Properties
  PROPERTY_VIEW = "property_view",
  PROPERTY_CREATE = "property_create",
  PROPERTY_EDIT = "property_edit",
  PROPERTY_DELETE = "property_delete",
  // Promotions
  PROMOTION_VIEW = "promotion_view",
  PROMOTION_CREATE = "promotion_create",
  PROMOTION_EDIT = "promotion_edit",
  PROMOTION_DELETE = "promotion_delete",
  // Activities
  ACTIVITY_VIEW = "activity_view",
  ACTIVITY_CREATE = "activity_create",
  ACTIVITY_EDIT = "activity_edit",
  ACTIVITY_DELETE = "activity_delete",
  // Lodging Types
  LODGING_TYPE_VIEW = "lodging_type_view",
  LODGING_TYPE_CREATE = "lodging_type_create",
  LODGING_TYPE_EDIT = "lodging_type_edit",
  LODGING_TYPE_DELETE = "lodging_type_delete",
  // Amenities
  AMENITY_VIEW = "amenity_view",
  AMENITY_CREATE = "amenity_create",
  AMENITY_EDIT = "amenity_edit",
  AMENITY_DELETE = "amenity_delete",
  // Regions
  REGION_VIEW = "region_view",
  REGION_CREATE = "region_create",
  REGION_EDIT = "region_edit",
  REGION_DELETE = "region_delete",
  // Local Recs
  LOCAL_REC_VIEW = "local_rec_view",
  LOCAL_REC_CREATE = "local_rec_create",
  LOCAL_REC_EDIT = "local_rec_edit",
  LOCAL_REC_DELETE = "local_rec_delete",
  // Local Rec Types
  LOCAL_REC_TYPE_VIEW = "local_rec_type_view",
  LOCAL_REC_TYPE_CREATE = "local_rec_type_create",
  LOCAL_REC_TYPE_EDIT = "local_rec_type_edit",
  LOCAL_REC_TYPE_DELETE = "local_rec_type_delete",
  // Partners
  PARTNER_VIEW = "partner_view",
  PARTNER_CREATE = "partner_create",
  PARTNER_EDIT = "partner_edit",
  PARTNER_DELETE = "partner_delete",
  // Group Event Types
  GROUP_EVENT_TYPE_VIEW = "group_event_type_view",
  GROUP_EVENT_TYPE_CREATE = "group_event_type_create",
  GROUP_EVENT_TYPE_EDIT = "group_event_type_edit",
  GROUP_EVENT_TYPE_DELETE = "group_event_type_delete",
  // Announcement Bar
  ANNOUNCEMENT_BAR_VIEW = "announcement_bar_view",
  ANNOUNCEMENT_BAR_CREATE = "announcement_bar_create",
  ANNOUNCEMENT_BAR_EDIT = "announcement_bar_edit",
  ANNOUNCEMENT_BAR_DELETE = "announcement_bar_delete",
  // Home Pages
  HOME_PAGE_VIEW = "home_page_view",
  HOME_PAGE_CREATE = "home_page_create",
  HOME_PAGE_EDIT = "home_page_edit",
  HOME_PAGE_DELETE = "home_page_delete",
  // Group Event Pages
  GROUP_EVENT_PAGE_VIEW = "group_event_page_view",
  GROUP_EVENT_PAGE_CREATE = "group_event_page_create",
  GROUP_EVENT_PAGE_EDIT = "group_event_page_edit",
  GROUP_EVENT_PAGE_DELETE = "group_event_page_delete",
  // Guest Reviews
  GUEST_REVIEW_VIEW = "guest_review_view",
  GUEST_REVIEW_CREATE = "guest_review_create",
  GUEST_REVIEW_EDIT = "guest_review_edit",
  GUEST_REVIEW_DELETE = "guest_review_delete",
  // About Us Pages
  ABOUT_PAGE_VIEW = "about_page_view",
  ABOUT_PAGE_CREATE = "about_page_create",
  ABOUT_PAGE_EDIT = "about_page_edit",
  ABOUT_PAGE_DELETE = "about_page_delete",
  // Careers Pages
  CAREER_PAGE_VIEW = "career_page_view",
  CAREER_PAGE_CREATE = "career_page_create",
  CAREER_PAGE_EDIT = "career_page_edit",
  CAREER_PAGE_DELETE = "career_page_delete",
  // Develop with Us Pages
  DEVELOP_PAGE_VIEW = "develop_page_view",
  DEVELOP_PAGE_CREATE = "develop_page_create",
  DEVELOP_PAGE_EDIT = "develop_page_edit",
  DEVELOP_PAGE_DELETE = "develop_page_delete",
  // Giving Back Pages
  GIVING_BACK_PAGE_VIEW = "giving_back_page_view",
  GIVING_BACK_PAGE_CREATE = "giving_back_page_create",
  GIVING_BACK_PAGE_EDIT = "giving_back_page_edit",
  GIVING_BACK_PAGE_DELETE = "giving_back_page_delete",
  // Our Family Pages
  FAMILY_PAGE_VIEW = "family_page_view",
  FAMILY_PAGE_CREATE = "family_page_create",
  FAMILY_PAGE_EDIT = "family_page_edit",
  FAMILY_PAGE_DELETE = "family_page_delete",
  // Partnership Pages
  PARTNERSHIP_PAGE_VIEW = "partnership_page_view",
  PARTNERSHIP_PAGE_CREATE = "partnership_page_create",
  PARTNERSHIP_PAGE_EDIT = "partnership_page_edit",
  PARTNERSHIP_PAGE_DELETE = "partnership_page_delete",
  // Contact Us Pages
  CONTACT_PAGE_VIEW = "contact_page_view",
  CONTACT_PAGE_CREATE = "contact_page_create",
  CONTACT_PAGE_EDIT = "contact_page_edit",
  CONTACT_PAGE_DELETE = "contact_page_delete",
  // Journal Articles
  JOURNAL_ARTICLE_VIEW = "journal_article_view",
  JOURNAL_ARTICLE_CREATE = "journal_article_create",
  JOURNAL_ARTICLE_EDIT = "journal_article_edit",
  JOURNAL_ARTICLE_DELETE = "journal_article_delete",
  // Journal Tags
  JOURNAL_TAG_VIEW = "journal_tag_view",
  JOURNAL_TAG_CREATE = "journal_tag_create",
  JOURNAL_TAG_EDIT = "journal_tag_edit",
  JOURNAL_TAG_DELETE = "journal_tag_delete",
  // Mobile App
  MOBILE_APP_VIEW = "mobile_app_view",
  MOBILE_APP_CREATE = "mobile_app_create",
  MOBILE_APP_EDIT = "mobile_app_edit",
  MOBILE_APP_DELETE = "mobile_app_delete",
  // Data Event Pages
  DATA_EVENT_PAGE_VIEW = "data_event_page_view",
  DATA_EVENT_PAGE_CREATE = "data_event_page_create",
  DATA_EVENT_PAGE_EDIT = "data_event_page_edit",
  DATA_EVENT_PAGE_DELETE = "data_event_page_delete",
  // Privacy Policy Pages
  PRIVACY_PAGE_VIEW = "privacy_page_view",
  PRIVACY_PAGE_CREATE = "privacy_page_create",
  PRIVACY_PAGE_EDIT = "privacy_page_edit",
  PRIVACY_PAGE_DELETE = "privacy_page_delete",
  // Accessibility Pages
  ACCESSIBILITY_PAGE_VIEW = "accessibility_page_view",
  ACCESSIBILITY_PAGE_CREATE = "accessibility_page_create",
  ACCESSIBILITY_PAGE_EDIT = "accessibility_page_edit",
  ACCESSIBILITY_PAGE_DELETE = "accessibility_page_delete",
  // Destinations Terms Pages
  DESTINATION_TERMS_PAGE_VIEW = "destination_terms_page_view",
  DESTINATION_TERMS_PAGE_CREATE = "destination_terms_page_create",
  DESTINATION_TERMS_PAGE_EDIT = "destination_terms_page_edit",
  DESTINATION_TERMS_PAGE_DELETE = "destination_terms_page_delete",
  // Terms and Conditions Pages
  TERMS_PAGE_VIEW = "terms_page_view",
  TERMS_PAGE_CREATE = "terms_page_create",
  TERMS_PAGE_EDIT = "terms_page_edit",
  TERMS_PAGE_DELETE = "terms_page_delete",
}

export const ROLES_MAP = [
  {
    id: "property",
    label: "Properties",
  },
  {
    id: "promotion",
    label: "Promotions",
  },
  {
    id: "activity",
    label: "Activities",
  },
  {
    id: "lodging_type",
    label: "Lodging Types",
  },
  {
    id: "amenity",
    label: "Amenities",
  },
  {
    id: "region",
    label: "Regions",
  },
  {
    id: "local_recs",
    label: "Local Recs",
  },
  {
    id: "local_rec_type",
    label: "Local Rec Types",
  },
  {
    id: "partner",
    label: "Partners",
  },
  {
    id: "group_event_type",
    label: "Group Event Types",
  },
  {
    id: "announcement_bar",
    label: "Announcement Bar",
  },
  {
    id: "home_page",
    label: "Home",
  },
  {
    id: "group_event_page",
    label: "Group Event",
  },
  {
    id: "guest_review",
    label: "Guest Reviews",
  },
  {
    id: "about_page",
    label: "About Us",
  },
  {
    id: "career_page",
    label: "Careers",
  },
  {
    id: "develop_page",
    label: "Develop with Us",
  },
  {
    id: "giving_back_page",
    label: "Giving Back",
  },
  {
    id: "family_page",
    label: "Our Family",
  },
  {
    id: "partnership_page",
    label: "Partnership",
  },
  {
    id: "contact_page",
    label: "Contact Us",
  },
  {
    id: "journal_article",
    label: "Journal Articles",
  },
  {
    id: "journal_tag",
    label: "Journal Tags",
  },
  {
    id: "mobile_app",
    label: "Mobile App",
  },
  {
    id: "data_event_page",
    label: "Data Event",
  },
  {
    id: "privacy_page",
    label: "Privacy Policy",
  },
  {
    id: "accessibility_page",
    label: "Accessibility",
  },
  {
    id: "destination_terms_page",
    label: "Destinations Terms",
  },
  {
    id: "terms_page",
    label: "Terms and Conditions",
  },
];

const getUserRoles = (): ROLES[] => {
  const rawRoles = Cookies.get("roles");

  if (!rawRoles) {
    return [];
  }

  const roles = rawRoles?.split(",");

  return roles as ROLES[];
};

const includesAll = (arr: any[], values: any[]) =>
  values.every((v) => arr.includes(v));

export const userHasRoles = (roles: ROLES[]) => {
  const userRoles = getUserRoles();

  console.log(userRoles);

  if (userRoles.includes(ROLES.SUPER_ADMIN)) {
    console.log("You are super user.");
    return true;
  }

  if (includesAll(userRoles, roles)) {
    console.log("Your role is included.", roles);
    return true;
  }

  return false;
};
