import UserItem from "./UserItem";
import "./UserRoleManager.css";

const UserRoleManager = ({
  users,
  isFetching,
  refetchUserList,
}: {
  users: any[];
  isFetching: boolean;
  refetchUserList: any;
}) => {
  return (
    <div className={`${isFetching ? "loading-wrapper" : ""}`}>
      <div>
        {users.map((u) => {
          return (
            <UserItem user={u} key={u.id} refetchUserList={refetchUserList} />
          );
        })}
      </div>
    </div>
  );
};

export default UserRoleManager;
