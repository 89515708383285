import { useEffect, useState } from "react";
import { Button, useDataProvider } from "react-admin";
import "./styles.css";
import UserAddAdminManager from "../../components/user-management/UserAddAdminManager";
import UserRoleManager from "../../components/user-management/UserRoleManager";

const UserManagement = () => {
  const dataProvider = useDataProvider();
  const [adminUsers, setAdminUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [openAddUser, setOpenAddUser] = useState(false);

  const fetchUserAdmins = async () => {
    try {
      setLoading(true);

      const { data } = await dataProvider.getMany("users", { ids: [] });

      setAdminUsers(data);

      console.log(data);

      setLoading(false);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserAdmins();
  }, []);

  return (
    <div className="user-management-wrapper">
      <div className="users-heading">
        <h2>User Management</h2>
        <div>
          {openAddUser ? (
            <Button
              onClick={() => setOpenAddUser(false)}
              label="- Cancel"
              variant="text"
            />
          ) : (
            <Button
              onClick={() => setOpenAddUser(true)}
              disabled={openAddUser}
              label="+ Add New Admin User"
              variant="text"
            />
          )}
        </div>
      </div>

      {openAddUser ? (
        <UserAddAdminManager refetchUserList={fetchUserAdmins} />
      ) : null}

      <UserRoleManager
        users={adminUsers}
        isFetching={loading}
        refetchUserList={fetchUserAdmins}
      />
    </div>
  );
};

export default UserManagement;
