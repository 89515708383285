import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/VpnKey";
import { Resource } from "../../enums";
import UserManagement from "./UserManagement";

const userManagement: ResourceProps = {
  name: Resource.USERS,
  icon: Icon,
  list: UserManagement,
  options: {
    label: "User Management",
  },
};

export default userManagement;
