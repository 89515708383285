import type { ResourceProps } from "react-admin";
import properties from "./properties";
import activities from "./activities";
import lodgingTypes from "./lodging-types";
import amenities from "./amenities";
import regions from "./regions";
import partners from "./partners";
import localRecommendationTypes from "./local-recommendation-types";
import localRecommendations from "./local-recommendations";
import groupEvents from "./group-events";
import journalTags from "./journal-tags";
import journalArticles from "./journal-articles";
import givingBack from "./giving-back-pages";
import homePages from "./home-pages";
import groupsPages from "./groups";
import aboutUsPages from "./about-us-pages";
import careersPages from "./careers-pages";
import contactUsPages from "./contact-us-pages";
import developWithUsPages from "./develop-with-us-pages";
import ourFamilyPages from "./our-family-pages";
import promotionsPages from "./promotions-pages";
import partnerWithUsPages from "./partnership-pages";
import accessibilityPage from "./accessibility-pages";
import destinationTermsPages from "./destination-terms-pages";
import privacyPolicyPages from "./privacy-policy-pages";
import termsAndConditionsPages from "./terms-and-conditions-pages";
import dataEventPages from "./data-events-pages";
import announcementsPage from "./announcements-banners";
import mobileAppsPage from "./app";
import reviews from "./reviews";
import userManagement from "./user-management";

import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { userHasRoles, ROLES } from "../utils/userRoles";

import eventsPage from "./events";

function resourceTreeReducer(tree: ResourceListItem[]): ResourceProps[] {
  return tree.reduce<ResourceProps[]>((acc, item) => {
    if (!item.resources?.length) {
      acc.push(item);
    } else {
      acc.push(...resourceTreeReducer(item.resources));
    }

    return acc;
  }, []);
}

export type ResourceListItem = ResourceProps & {
  resources?: ResourceListItem[];
};

export const resourcesTree: ResourceListItem[] | any[] = [
  userHasRoles([ROLES.PROPERTY_VIEW]) && { ...properties },
  promotionsPages,
  // eventsPage,
  {
    name: "attributes",
    icon: AppsOutlinedIcon,
    options: { label: "Attributes" },
    resources: [
      activities,
      lodgingTypes,
      amenities,
      regions,
      localRecommendations,
      localRecommendationTypes,
      partners,
      groupEvents,
    ],
  },
  {
    name: "website",
    icon: WebAssetOutlinedIcon,
    options: { label: "Website" },
    resources: [
      announcementsPage,
      homePages,
      groupsPages,
      reviews,
      {
        name: "our-story",
        options: { label: "Our Story" },
        resources: [
          aboutUsPages,
          careersPages,
          developWithUsPages,
          givingBack,
          ourFamilyPages,
          partnerWithUsPages,
          contactUsPages,
        ],
      },
      {
        name: "journal",
        options: { label: "Journal" },
        resources: [journalArticles, journalTags],
      },
      mobileAppsPage,
    ],
  },
  {
    name: "legal",
    icon: InfoOutlinedIcon,
    options: { label: "Legal" },
    resources: [
      dataEventPages,
      privacyPolicyPages,
      accessibilityPage,
      destinationTermsPages,
      termsAndConditionsPages,
    ],
  },
];

if (userHasRoles([ROLES.SUPER_ADMIN])) {
  resourcesTree.push(userManagement);
}

const resources = resourceTreeReducer(resourcesTree);

export default resources;
