import { useState } from "react";
import "./UserItem.css";
import { ROLES_MAP } from "../../utils/userRoles";
import { Button, useDataProvider } from "react-admin";

const UserItem = ({
  user,
  refetchUserList,
}: {
  user: any;
  refetchUserList: any;
}) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { roles: rolesRaw } = user;
  const roles = rolesRaw.split(",");

  const [userRoles, setUserRoles] = useState(roles);

  const onRemoveAsAdmin = async () => {};

  const hasPermission = (permissionId: string) => {
    return userRoles.includes(permissionId);
  };

  const togglePermission = (permissionId: string) => {
    if (hasPermission(permissionId)) {
      setUserRoles(userRoles.filter((r: string) => r !== permissionId));
    } else {
      setUserRoles([...userRoles, permissionId]);
    }
  };

  const toggleAllPermissions = (prefix: string) => {
    const removedAllPermissions = userRoles.filter(
      (r: string) => !r.startsWith(prefix),
    );

    if (hasAllPermissions(prefix)) {
      setUserRoles(removedAllPermissions);
    } else {
      setUserRoles([
        ...removedAllPermissions,
        ...[
          `${prefix}_view`,
          `${prefix}_create`,
          `${prefix}_delete`,
          `${prefix}_edit`,
        ],
      ]);
    }
  };

  const discardChanges = () => {
    setUserRoles(roles);
  };

  const onConfirm = async () => {
    setLoading(true);
    setError(null);

    try {
      const newRoles = userRoles.join(",");
      // Set user as admin.
      const { data } = await dataProvider.updateUserById(user.id, {
        roles: newRoles,
      });
    } catch (e) {
      setError("Error setting user permissions!");
    } finally {
      setLoading(false);
    }
  };

  const hasAllPermissions = (prefix: string) => {
    return (
      hasPermission(`${prefix}_view`) &&
      hasPermission(`${prefix}_create`) &&
      hasPermission(`${prefix}_delete`) &&
      hasPermission(`${prefix}_edit`)
    );
  };

  const onRemoveAdmin = async () => {
    setLoading(true);
    setError(null);

    try {
      // Set user as admin.
      const { data } = await dataProvider.updateUserById(user.id, {
        isAdmin: false,
      });

      refetchUserList();
    } catch (e) {
      setError("Error removing user as admin!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-item-wrapper">
      <div
        className="user-item-heading"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {user.lastName}, {user.firstName} ({user.email})
      </div>
      {isOpen ? (
        <div className="user-item-attr">
          <div className="permission-crud-label">Permissions</div>
          <div className="permission-super-admin">
            <div onClick={() => togglePermission(`super_admin`)}>
              <input type="checkbox" checked={hasPermission(`super_admin`)} />{" "}
              <label>Super Admin</label>
            </div>
          </div>
          <div className="permissions-grid">
            {!hasPermission(`super_admin`) &&
              ROLES_MAP.map((roleMap) => {
                const { id, label } = roleMap;

                return (
                  <div key={id} className="permission-crud-wrapper">
                    <div
                      className="permission-crud-label"
                      onClick={() => toggleAllPermissions(id)}
                    >
                      <input type="checkbox" checked={hasAllPermissions(id)} />{" "}
                      {label}
                    </div>
                    <div className="permissions-crud">
                      <div onClick={() => togglePermission(`${id}_view`)}>
                        <input
                          type="checkbox"
                          checked={hasPermission(`${id}_view`)}
                        />{" "}
                        <label>View</label>
                      </div>
                      <div onClick={() => togglePermission(`${id}_edit`)}>
                        <input
                          type="checkbox"
                          checked={hasPermission(`${id}_edit`)}
                        />{" "}
                        <label>Edit</label>
                      </div>
                      <div onClick={() => togglePermission(`${id}_create`)}>
                        <input
                          type="checkbox"
                          checked={hasPermission(`${id}_create`)}
                        />{" "}
                        <label>Create</label>
                      </div>
                      <div onClick={() => togglePermission(`${id}_delete`)}>
                        <input
                          type="checkbox"
                          checked={hasPermission(`${id}_delete`)}
                        />{" "}
                        <label>Delete</label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {error ? <div className="error-bar">{error}</div> : null}

          {loading ? <div className="loading-bar">Loading...</div> : null}

          <div className="user-item-cmds">
            <Button
              onClick={onConfirm}
              label="Save Changes"
              variant="contained"
              disabled={loading}
              style={{
                marginRight: "10px",
              }}
            />
            <Button
              onClick={discardChanges}
              label="Discard Changes"
              disabled={loading}
              variant="text"
              style={{
                marginRight: "10px",
              }}
            />
            <Button
              onClick={onRemoveAdmin}
              label="- Remove User From Admins"
              variant="text"
              disabled={loading}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserItem;
