import { useState } from "react";
import "./UserAddAdminManager.css";
import { Button, useDataProvider } from "react-admin";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";

const UserAddAdminManager = ({ refetchUserList }: { refetchUserList: any }) => {
  const dataProvider = useDataProvider();
  const [fetchedUser, setFetchedUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [email, setEmail] = useState<string>("");

  const onCancel = () => {
    setFetchedUser(null);
    setLoading(false);
    setError(null);
  };

  const onConfirm = async () => {
    setLoading(true);
    setError(null);

    try {
      // Set user as admin.
      const { data } = await dataProvider.updateUserById(fetchedUser.id, {
        isAdmin: true,
      });

      refetchUserList();

      setFetchedUser(null);
    } catch (e) {
      setError("Error setting user as admin!");
    } finally {
      setLoading(false);
    }
  };

  const onFind = async () => {
    // Validations...
    if (email === "") {
      setError("E-mail can't be empty.");
      return;
    }

    setFetchedUser(null);
    setLoading(true);
    setError(null);

    try {
      // Find user
      const { data } = await dataProvider.getUserByEmail(email);

      setFetchedUser(data);
    } catch (e) {
      setError("User not found!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-admin-wrapper">
      <div className="admin-search-bar">
        <div className="admin-search-label">New admin e-mail:</div>
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Search by email..."
          />
        </div>
        <div>
          <Button onClick={onFind} label="Find" variant="text" />
        </div>
      </div>

      {error ? <div className="error-bar">{error}</div> : null}

      {loading ? <div className="loading-bar">Loading...</div> : null}

      {fetchedUser ? (
        <div className="fetched-user">
          <div>
            <strong>Fetched User:</strong>
          </div>
          <div>First Name: {fetchedUser.firstName}</div>
          <div>Last Name: {fetchedUser.last}</div>
          <div>Email: {fetchedUser.email}</div>
          <div className="admin-cmds">
            <Button
              onClick={onConfirm}
              label="Make User Admin!"
              variant="contained"
              style={{
                marginRight: "10px",
              }}
            />
            <Button onClick={onCancel} label="Cancel" variant="text" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserAddAdminManager;
